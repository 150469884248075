$stb-primary-color: #00f6ff;
$stb-primary-color-light: #77faff;
$stb-primary-color-dark: #00d5dd;

$stb-secondary-color: #0b0523;
$stb-secondary-color-light: #d5cef0;

$stb-blue: #003bbbdc;
$stb-green: #00ab55;
$stb-grey: #667085;

$stb-bg-yellow: #fff7cd;
$stb-bg-blue: #d0f2ff;
$stb-bg-green: #c8facd;
$stb-bg-red: #ffe7d9;

$stb-text-yellow: rgb(122, 79, 1);
$stb-text-blue: rgb(4, 41, 122);
$stb-text-green: rgb(0, 82, 73);
$stb-text-red: rgb(124, 15, 48);

$stb-link-color: #001fcc;
$stb-border-color: #eaecf0;

$stb-s-4: 4px;
$stb-s-8: 8px;
$stb-s-10: 10px;
$stb-s-12: 12px;
$stb-s-14: 14px;
$stb-s-16: 16px;
$stb-s-18: 18px;
$stb-s-20: 20px;
