@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

$inter: "Inter", sans-serif;

p {
  margin: 0 !important;
}

.w-35 {
  width: 35px;
}

.primary_color {
  background: #0b0523 !important;
}

.sidebar_primary_color {
  background: #000 !important;
}

.light_color {
  color: #808191 !important;
}

.white_color {
  color: #fff !important;
}

.light_bg {
  background-color: #8f92a108 !important;
}

.grey_bg {
  background-color: #ede9e9 !important;
}

.light-bg {
  background: #d3d3d399 !important;
}
.secondary {
  color: $stb-primary-color !important;
}

.colr:hover {
  color: $stb-primary-color !important;
  text-decoration: none !important;
}

body {
  font-family: $inter !important;
  height: 100%;
  background-color: white !important;
  color: #101828 !important;
}

.bgchild {
  background: #231f3a !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.sidebar .nav-item .nav-link:hover {
  background: #231f3a !important;
}
.sidebar-text:hover {
  color: $stb-primary-color !important;
}

.bg-prim {
  background-color: #0b0523 !important;
}

.border-none {
  border: none !important;
}
.box-shad-none {
  box-shadow: none !important;
}

.nav-item a {
  border: none !important;
  box-shadow: none !important;
  font-size: 14px !important;
}

.h55 {
  height: 55px !important;
}

.sidebar .nav-link .sidebar-icon {
  margin-right: 5px !important;
}

.sidebar-inner .accordion-button:not(.collapsed) {
  background-color: #231f3a !important;
  color: white !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

/* .sidebar-inner .accordion-button .sidebar-text:hover {
    background-color: #231f3a!important;
    color: white!important;
} */

.sidebar .nav-item.active > .nav-link {
  background-color: #231f3a !important;
}

.sidebar .nav-item.active > .nav-link {
  color: $stb-primary-color;
}

.sidebar .nav-item.active > .nav-link .sidebar-icon {
  color: $stb-primary-color !important;
}

.sidebar .nav-item.active > .nav-link .sidebar-icon:before {
  content: "";
  width: 3px;
  height: 20px;
  position: absolute;
  background: $stb-primary-color;
  left: -11px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
}

.sidebar .nav-item.active > .nav-link .sidebar-text {
  color: $stb-primary-color !important;
}

.color-grey {
  color: lightgrey !important;
}

.creation_date {
  color: grey;
  font-size: 12px;
}
.virtual {
  font-size: 12px;
}

.logo-text img {
  width: 200px;
}

.page-title {
  font-weight: 500;
  font-size: 2rem;
  color: #101828;
}

.cards {
  box-shadow: 0px 10px 10px 20px #b0b8d61c, 10px 10px 15px -5px #afbef7;
  border-radius: 8px;
  background: white;
}

.font-700 {
  font-weight: 700 !important;
}

.white-box {
  background-color: white;
  border-radius: 15px;
  padding: 20px !important;
  margin-bottom: 0px !important;
}

.white-box .box-title {
  line-height: normal;
  font-family: $inter !important;
  font-size: 18px;
}
.box-title {
  margin: 0px;
}
.f13-px {
  font-size: 13px;
}

.color-blue {
  color: blue !important;
}

.right-border:after {
  content: "";
  position: absolute;
  width: 90px;
  height: 1px;
  background: #b2b2b2;
  left: 431px;
  transform: rotate(90deg);
  top: 191px;
}
.right-border2:after {
  content: "";
  position: absolute;
  width: 78px;
  height: 1px;
  background: #d1d1d1;
  left: 780px;
  transform: rotate(90deg);
  top: 279px;
}

.colorchange tr th {
  background: #0b0523 !important;
  color: white !important;
  font-weight: 100;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}

.center tr td {
  text-align: center;
}

.font-16 {
  font-size: 16px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-11 {
  font-size: 11px !important;
}

.text-cy {
  color: #424e79 !important;
}

.sidebar-nav ul .sidebar-item .first-level .sidebar-item.active .sidebar-link {
  background: none !important;
  color: $stb-primary-color !important;
}
.labl {
  font-size: 11px;
  color: darkgrey;
}

.customform {
  background: #f4f5f7;
  border: 1px solid grey;
  border-radius: 15px;
  height: 55px !important;
}
.cancel {
  border-radius: 12px !important;
  background: lightgrey !important;
  padding: 6px 90px !important;
  transition: 0.5s !important;
}

.change {
  border-radius: 12px;
  background: #0b0423 !important;
  color: white !important;
  padding: 6px 50px;
}
.change:hover {
  color: white;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  transform: scale(0.85) translateY(-1rem) translateX(1.3rem);
}

.form-floating > .form-control,
.form-floating > .form-select {
  padding: 0;
}

.radi {
  border-radius: 15px !important;
}
th:first-child {
  border-bottom-left-radius: 17px;
  border-top-left-radius: 17px;
}

th:last-child {
  border-bottom-right-radius: 17px;
  border-top-right-radius: 17px;
}

.clear {
  background: none;
  color: $stb-primary-color !important;
}
.bg-lights {
  background: white !important;
}

.darkgrey {
  color: darkgray;
}

.load {
  background: #0b0423 !important;
}

a:hover {
  text-decoration: none !important;
}

.bg-main {
  background: #0b0423 !important;
  color: white !important;
}

.bicon {
  background: #ff7f00;
  color: white;
  border-radius: 100px;
  padding: 2px;
}

.exchange {
  background: blue;
  color: white;
  border-radius: 100px;
  padding: 2px;
}

h3 {
  line-height: normal !important;
}

.w-35 {
  width: 35px;
}

.rs-sidenav .rs-nav-item .rs-nav-item-content,
.rs-sidenav .rs-dropdown .rs-dropdown-toggle {
  padding-left: 30px !important;
}

.rs-sidenav-collapse-in .rs-dropdown > .rs-dropdown-menu {
  background: #231f3a !important;
  color: white !important;
}

.rs-nav-default
  .rs-nav-item:not(.rs-nav-item-active):not(.rs-nav-item-disabled)
  > .rs-nav-item-content:active {
  background: #231f3a !important;
  color: white !important;
}

.rs-btn-subtle:active:hover,
.rs-btn-subtle.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle:hover,
.rs-btn-subtle:active:focus,
.rs-btn-subtle.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle:focus,
.rs-btn-subtle:active.focus,
.rs-btn-subtle.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.focus {
  background: #231f3a !important;
  color: white !important;
}

.rs-btn-subtle:active:hover,
.rs-btn-subtle.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle:hover,
.rs-btn-subtle:active:focus,
.rs-btn-subtle.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle:focus,
.rs-btn-subtle:active.focus,
.rs-btn-subtle.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.focus {
  background: #231f3a !important;
  color: white !important;
}

.rs-btn {
  font-size: $stb-s-16 !important;
}

.rs-sidenav-collapse-in .rs-dropdown > .rs-dropdown-menu {
  border-radius: 8px !important;
  margin: 10px;
  margin-top: 0px;
}

li.rs-nav-item:hover:focus {
  background: #231f3a !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-fam {
  font-family: $inter !important;
}

.nav-tabs .nav-link.active {
  text-decoration: none !important;
}

/* .fade {
  opacity: 1!important;
  pointer-events: all!important;
} */

/* .form-floating > label {
  padding: 0.5em 0.75em!important;
} */

.transparent_bg {
  background: rgb(248, 248, 248) !important;
}

.base-border {
  border-left: 10px solid red;
}
.form-control {
  box-shadow: none !important;
}

.h-100p {
  height: 100px !important;
}

.font-50 {
  font-size: 50px !important;
}

.f-900 {
  font-weight: 900 !important;
}

.box-shadow-0 {
  box-shadow: none !important;
}

.w-50px {
  width: 50px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-16 {
  font-size: 16px !important;
}

.centerbtn {
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
}

.icon-shape span,
.icon-shape svg {
  font-size: 4rem !important;
}

.line-height-0 {
  line-height: 0 !important;
}

.effect {
  background: white;
  padding: 10px;
  box-shadow: 0px 0px 3px grey;
  border-left: 2px solid #ff3737;
  border-radius: 3px;
}

.staffeffect {
  background: white;
  padding: 10px;
  box-shadow: 0px 0px 3px grey;
  border-left: 2px solid #44a317;
  border-radius: 3px;
  float: left;
  width: 100%;
}

.float-right {
  float: right !important;
}

.usereffect {
  background: white;
  padding: 10px;
  box-shadow: 0px 0px 3px grey;
  border-right: 2px solid #35baee;
  border-radius: 3px;
  float: right;
  width: 100%;
}

.stb-content-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #0b0523;
}

.stb-w-100 {
  width: 100vw;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}

.content-block {
  text-align: center;
  h1 {
    font-size: 2.8rem;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 30px;
  }
}

.stars {
  margin-bottom: 2rem;
  img {
    width: 1.6rem;
    margin-right: 0.5rem;
  }
}

.avatars {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin-left: 1rem;
    color: white;
    font-size: 1.3rem;
    font-weight: 500;
  }
}

.logo {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.material-inp {
  line-height: 2.2rem;
}

.back,
.next {
  cursor: pointer;
  width: fit-content;
  display: flex;
  justify-content: left;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  background: none;
  img {
    margin-right: 8px;
  }

  @media screen and (max-width: 880px) {
    img {
      width: 10px;
    }

    span {
      font-size: 12px;
    }
  }
}

.next {
  justify-content: right;
  img {
    margin: 0 0 0 8px;
    transform: rotateY(180deg);
  }
}

.status {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 8px;
}

.line {
  margin: 0 3px;
  width: 50%;
  height: 0;
  border-bottom: 1px dashed #0b0523;
}

.solid {
  border-bottom: 1px solid #0b0523;
}

.part {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-weight: 600;
  }
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: white;
  margin-right: 1rem;
  color: black;
  font-weight: 600;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.3);
}

.filled {
  background-color: #0b0523;
  color: white;
}

.socials {
  cursor: pointer;
  position: absolute;
  bottom: 2rem;
  color: #e9d7fe;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 2rem;
  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}

.social:visited,
.social:focus,
.social:active,
.social:hover {
  color: #e9d7fe;
  text-decoration: none;
}

.twitter {
  left: 2rem;
}

.mail {
  right: 2rem;
  img {
    margin-right: 5px;
  }
}

.font-100 {
  font-weight: 100 !important;
}

.lebel-text {
  font-size: 0.8rem;
  font-weight: 700;
  color: #1c2437;
}
.custom-radio {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 135px;
  flex: 0 0 135px;
  height: 60px;
  margin-right: 20px;
}

.custom-radio .custom-control-input {
  display: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-radio .custom-control-label {
  cursor: pointer;
  background: #ffffff;
  height: 60px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 400;
  color: #b2becc;
  padding-left: 50px;
  text-transform: capitalize;
  position: relative;
  top: 0;
  left: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-radio .custom-control-label:before {
  height: 18px;
  width: 18px;
  border-radius: 5px;
  background: #e1e7ee;
  border: none;
  top: 19px;
  left: 20px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.custom-radio .custom-control-label:after {
  height: 18px;
  width: 18px;
  top: 20px;
  left: 20px;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label {
  border-color: #01b681;
  color: #01b681;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: #01b681;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  content: url(check-white.svg);
}

.info {
  position: absolute;
  right: -26px;
  top: 0;
}

.wizard-part-title {
  color: #5756a2;
  font-size: 40px;
  font-weight: 700;
  text-align: left;
}

.form-group {
  margin-bottom: 25px;
}
.form-group label {
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #1c2437;
  margin-bottom: 8px;
}
label.form-group {
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #1c2437;
  margin-bottom: 8px;
}
.form-group .select-input {
  position: relative;
}

.form-group .select-input span {
  position: absolute;
  top: 27px;
  right: 15px;
  height: 0;
  width: 0;
  border-top: 6px solid #b2becc;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.form-group .select-input .form-control {
  padding-right: 0;
  padding-left: 15px;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.form-group .form-control {
  background: #ffffff;
  height: 60px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  color: black !important;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0 25px;
  font-weight: 400;
}

.customformbg {
  background-color: #f4f2ff !important;
}

.customformbg:focus {
  background-color: #ffffff !important;
  border: 2px solid #eb9847 !important;
}

.errorhandler {
  color: white;
  background: red;
  width: 36%;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  border-radius: 3px;
}

.PrivateNotchedOutline-root-1 {
  top: 0px !important;
}

.MuiFormLabel-root {
  background-color: white;
}

.text-red {
  color: red;
}

.no-radius {
  border-radius: 0px !important;
}

.back_button {
  position: absolute !important;
}
.PrivateTabIndicator-colorSecondary-1,
.PrivateTabIndicator-colorSecondary-2,
.PrivateTabIndicator-colorSecondary-3,
.PrivateTabIndicator-colorSecondary-4,
.PrivateTabIndicator-colorSecondary-5,
.PrivateTabIndicator-colorSecondary-6,
.PrivateTabIndicator-colorSecondary-7,
.PrivateTabIndicator-colorSecondary-8,
.PrivateTabIndicator-colorSecondary-9,
.PrivateTabIndicator-colorSecondary-10,
.PrivateTabIndicator-colorSecondary-11,
.PrivateTabIndicator-colorSecondary-12,
.PrivateTabIndicator-colorSecondary-13,
.PrivateTabIndicator-colorSecondary-14,
.PrivateTabIndicator-colorSecondary-15 {
  background-color: #0b0523 !important;
}
@media only screen and (max-width: 767px) {
  .stb-content-block {
    display: none;
  }

  .msignupbg {
    background: white;
    background-size: cover;
  }
  .back_button {
    position: absolute !important;
    left: 10px;
  }
  .wizard-part-title {
    text-align: center;
    font-size: 22px;
  }

  .custom-databox {
    padding: 19px 10px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    margin-bottom: 17px;
  }

  .right-border:after {
    //     content: "";
    //     position: absolute;
    //     width: 90px;
    //     height: 1px;
    //     background: #dddddd;
    //     left: 162px;
    //     transform: rotate(
    // 90deg);
    //     top: 272px;
    display: none;
  }

  .size-xs {
    font-size: 11px !important;
  }
  .font-40-xs {
    font-size: 40px !important;
  }

  .w-xs-100 {
    width: 100% !important;
  }
}

.inbox_msg {
  border: 1px solid #d6d6d6;
  clear: both;
  overflow: hidden;
  border-radius: 15px;
}
.top_spac {
  margin: 20px 0 0;
}

.recent_heading {
  float: left;
  width: 40%;
}

.headind_srch {
  padding: 10px 29px 10px 20px;
  overflow: hidden;
  border-bottom: 1px solid #c4c4c4;
}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}

.chat_ib h5 {
  font-size: 15px;
  color: #464646;
  margin: 0 0 8px 0;
}
.chat_ib h5 span {
  font-size: 13px;
  float: right;
}
.chat_ib p {
  font-size: 14px;
  color: #989898;
  margin: auto;
}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people {
  overflow: hidden;
  clear: both;
}
.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}
.inbox_chat {
  height: 550px;
  overflow-y: scroll;
}

.active_chat {
  background: #ebebeb;
}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}
.outgoing_msg_img {
  display: inline-block;
  width: 6%;
  float: right;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 60%;
}
.received_withd_msg p {
  background: #e4e8fb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 23px 10px 5px 12px;
  width: 100%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 10px;
  margin: 3px 0 0;
}
.received_withd_msg {
  width: 70%;
}
.mesgs {
  padding: 13px;
}

.sent_msg p {
  background: #e4e8fb none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color: #646464;
  padding: 23px 10px 5px 12px;
  width: 100%;
}
.outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
}
.sent_msg {
  float: right;
  width: 40%;
  text-align: right;
  padding: 0 10px 0 0px;
}
.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {
  border-top: 1px solid #c4c4c4;
  position: relative;
}
.msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}
.messaging {
  background: #fff;
}
.msg_history {
  max-height: 516px;
  overflow-y: auto;
}

.font-25 {
  font-size: 25px !important;
}

.merchant-wallet {
  .merchant-wallet-key {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    margin: 0 auto 2rem;

    .secretkey {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 8px 0 0 8px;
      background-color: #ede9e9;
      padding: 4px 16px;
      height: 44px;
      font-size: 1rem;
      max-width: 70%;
      overflow: hidden;
    }

    .copy-btn {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      border-radius: 0 8px 8px 0;
      height: 44px;
      max-width: 30%;
      min-width: fit-content;
      font-size: 1rem;
      img {
        margin-right: 5px;
      }
    }
  }

  .wallet-modal {
    padding: 0 !important;
    // color:red;
  }

  .instructions {
    padding: 1rem;
    background-color: #ede9e9;
    margin-bottom: 1rem !important;
    border-radius: 8px;

    .instruction {
      .circle {
        //  width: 2.5rem;
        flex-shrink: 0;
      }
      .inst-content {
        line-height: 20px;
        ol {
          li {
            line-height: 20px;
          }
        }
      }
    }
  }

  .wallet-activate {
    @media screen and (max-width: 640px) {
      display: none;
    }
  }

  .wallet-activate-alt {
    display: none;
    @media screen and (max-width: 640px) {
      display: initial;
    }
  }

  .heading {
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    margin: 0 auto 1rem !important;
  }
}

.wallet-configure {
  .footer-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.captcha {
  flex-direction: column;
}

.action-button {
  margin: 10px 1rem 10px 1rem !important;
}

.hr {
  margin: 0;
  border: #ebebeb;
}

.dialog-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: black;
}

.copy-add {
  word-break: break-all;
  button {
    background: none;
    margin-left: 5px;
  }
  b {
    font-size: 1rem;
  }
}

.btn {
  cursor: pointer;
  font-size: 1rem !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  padding: 8px 12px !important;
  margin: 0 !important;
}

.white-btn {
  border: 1px solid #ebebeb !important;
  color: black !important;
}

.disabled {
  background-color: #615d71 !important;
  cursor: not-allowed !important;
}

.basis-30 {
  flex-grow: 0;
}

.basis-70 {
  flex-grow: 1;
}

.grow-1 {
  flex-grow: 1;
}

// Navbar

.nav-container {
  padding: 20px 32px !important;
  height: 80px;
}

.flex-row {
  flex: 0 0 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.flex-col {
  flex: 0 0 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.space-between {
  justify-content: space-between;
}

.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}

.align-start {
  align-items: flex-start;
}

.background {
  background: linear-gradient(
      0deg,
      rgba(11, 5, 35, 0.88),
      rgba(11, 5, 35, 0.88)
    ),
    url(../assets/img/signinBg.png);
  // background-size:300px 300px;
  background-position: 0 0;
  background-attachment: fixed;
}

.signupbg {
  background-image: url("../assets/img/loginBg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.br-8 {
  border-radius: 8px;
}

.qr-container {
  width: fit-content;
  padding: 1rem;
  margin: 0 auto;
}

.react-datepicker__input-container input {
  // width: inherit;
  height: 40px;
}

.stb-ph-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.stb-page-wrapper {
  height: calc(100vh - 80px);
}

.stb-h-100 {
  height: fit-content;
}

.trans-card {
  height: 200px;
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-width: 250px;

  // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  .card-content {
    padding: 1rem;
    text-align: center;
    p {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    span {
      font-size: 32px;
      font-weight: 700 !important;
    }
  }
}

.balance-cards {
  overflow-x: scroll;
  padding: $stb-s-16;
}

.balance-cards {
  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  // &::-webkit-scrollbar-thumb {
  // background: $stb-primary-color-light;
  // border-radius: 20px;
  // }

  // &::-webkit-scrollbar-thumb:hover {
  // background: $stb-primary-color-dark;
  // }
}

.filter {
  min-width: fit-content;
  flex-grow: 1;
}

.filters {
  @media screen and (max-width: 1470px) {
    overflow-x: scroll;
    overflow-y: visible;
    // padding: $stb-s-16;
    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    .dropdown-menu {
      position: static !important;
      transform: translate(0px, 0px) !important;
    }
  }
}

.super-colors {
  z-index: 10;
}
.dropdown-icon {
  min-width: 12px;
}

.overflow {
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}
.light-green {
  background-color: $stb-bg-green;
  // box-shadow: #C8FACD 0px 2px 8px 0px;
  color: $stb-text-green;
}

.light-red {
  background-color: $stb-bg-red;
  color: $stb-text-red;
}

.light-blue {
  background-color: $stb-bg-blue;
  // box-shadow: #D0F2FF 0px 2px 8px 0px;
  color: $stb-text-blue;
}

.light-yellow {
  background-color: $stb-bg-yellow;
  color: $stb-text-yellow;
}

.green {
  background-color: $stb-green;
}
.blue {
  background-color: $stb-blue;
  // box-shadow: #D0F2FF 0px 2px 8px 0px;
}
.yellow-dark {
  background-color: $stb-primary-color-dark;
}

.yellow {
  background-color: $stb-primary-color;
}

.yellow-light {
  background-color: $stb-primary-color-light;
}

.dash-hero {
  display: flex;
  height: 300px;
  border-top: 2px solid $stb-border-color;
  border-bottom: 2px solid $stb-border-color;
  .hero-graph {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 65%;
    border-right: 2px solid #f1f2f5;
    padding: 1rem 1rem 1rem 0;
    .hero-filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 35%;

      .home-dropdown {
        .dropdown-menu {
          right: 0 !important;
        }
      }
      .hero-stat-type {
        .stat {
          display: flex;
          gap: 10px;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          .stat-selector {
            width: 10px;
            height: 10px;
            border-radius: 50%;
          }
        }
      }
    }
    .hero-chart {
      height: 55%;
      position: relative;
      .rs-loader-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .hero-numbers {
    width: 40%;
    display: flex;
    flex-direction: column;
    .hero-balance {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      height: 50%;
      border-bottom: 2px solid $stb-border-color;
      button {
        width: 5rem;
        height: 2.5rem;
      }
    }
    .hero-payout {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      height: 50%;
      button {
        width: 5rem;
        height: 2.5rem;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    flex-direction: column;
    height: fit-content;
    .hero-graph {
      width: 100%;
      border-right: none;
      border-bottom: 2px solid #f1f2f5;
      padding: 1rem 0 0 0;

      .hero-chart {
        height: 150px;
      }
    }
    .hero-numbers {
      width: 100%;

      .hero-balance {
        padding: 1rem 0;
      }

      .hero-payout {
        padding: 1rem 0;
      }
    }

    .page-title {
      font-size: 20px;
    }

    .hero-stat-type {
      display: none;
    }
  }
}

.css-18lrjg1-MuiCircularProgress-root {
  color: $stb-primary-color !important;
}
.MuiLinearProgress-root {
  background-color: $stb-bg-yellow !important;
}
.MuiLinearProgress-bar {
  background-color: $stb-primary-color !important;
}

// .MuiDataGrid-overlay {
//   background-color: rgba(0, 0, 255, 0.8) !important;
//   backdrop-filter: blur(40px);
// }

// Recharts

.recharts-tooltip-item-name,
.recharts-tooltip-item-separator {
  color: #575757;
}

.recharts-default-tooltip {
  border-radius: 8px !important;
}

// DatePicker

.rs-picker-toggle-value {
  color: black !important;
}

.resend-otp-timer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resend-otp-btn {
  background: none;
  outline: none;
  color: $stb-link-color;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 1rem;
}

.custom-tooltip-stb {
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
}

.reports-container {
  // height: 300px;
  display: flex;
  flex-flow: wrap;
}

.report {
  flex: 1 0 25%;
  height: 300px;
  min-width: 300px;
  padding: $stb-s-16;
  border: 1px solid $stb-border-color;

  &-title {
    font-size: $stb-s-14;
    font-weight: 500;
    color: $stb-grey;
  }

  &-data {
    font-size: $stb-s-18;
    font-weight: 700;
  }
}

.chart-legend {
  color: $stb-grey;
}

.StripeElement {
  border: 1px solid #c4c4c4;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18.5px 14px;
  border-radius: 4px;
  .__PrivateStripeElement {
    width: 100%;
  }
}

.StripeElement--invalid {
  border: 1px solid $stb-text-red;
}

.red-btn {
  background-color: $stb-bg-red !important;
  color: $stb-text-red !important;
  border: 1px solid $stb-text-red !important;
}

.alert-msg {
  color: $stb-text-red;
  .link {
    font-weight: bolder;
    cursor: pointer;
  }
}

// KYB

.kyb-container {
  display: flex;
  align-items: center;
  justify-content: center;
  // @media screen and (max-width: 880px) {
  // flex-direction: column;

  // aside section{
  //   display: none;
  // }

  // }
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  border-radius: 8px !important;
}

.kyb-menu {
  min-width: 280px;
  flex-basis: 20%;
  padding: 1rem;
  .kyb-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #eaecf0;
    padding-bottom: 1rem;
    margin-bottom: 0.5rem;
    div {
      span {
        font-weight: 600;
      }
    }
  }

  .kyb-menu-items {
    span {
      font-weight: 600;
    }
    ul {
      padding: 0 !important;
      li {
        list-style: none;
        line-height: 2rem;
      }
      .active {
        font-weight: 500;
      }
    }
  }
}
.kyb-form {
  width: 80%;
  padding: 1rem;
  max-width: 1200px;

  header,
  article,
  footer {
    display: flex;
    justify-content: center;
  }
  header {
    border-bottom: 2px solid #eaecf0;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: center;
  }
  article {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 0 1rem 2rem;
    margin-bottom: 1rem;
    // img {
    //   width: 40%;
    //   max-width: 400px;
    //   min-width: 300px;
    // }
    .kyb-info {
      width: 45%;
      text-align: center;
    }
    form {
      width: 100%;
    }
    .doc-upload {
      display: flex;
      flex-direction: column;
    }
  }

  @media screen and (max-width: 880px) {
    padding: 1rem 0;
    width: 100%;

    header h4 {
      font-size: 15px;
    }
  }
}

.break {
  border-bottom: 2px solid #eaecf0;
  width: 98% !important;
  margin: 1.1rem auto 0 !important;
}

.pointer-bg {
  display: inline-block;
  width: 12px;
  aspect-ratio: 1;
  border-radius: 50%;
  position: relative;
  margin-right: 0.5rem;
}

.pointer-fg {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  aspect-ratio: 1;
  border-radius: 50%;
}

.page-header {
  align-items: center;
  display: flex;

  .tools {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 1220px) {
    display: block;
    .tools {
      padding: 1rem 0;
      display: flex;
      align-items: flex-start !important;
      max-width: 100%;
      overflow-x: auto;
      overflow-y: visible;
      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      div button {
        white-space: nowrap;
      }

      div p {
        margin-bottom: 1rem !important;
      }

      .dropdown-menu {
        position: static !important;
        transform: translate(0px, 0px) !important;
      }
    }
  }
}

.signup {
  display: flex;

  section {
    flex: 1;
    min-height: 100vh;

    .signup-form {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}

.totals-container {
  display: flex;
  // justify-content: space-evenly;
  .totals {
    flex: 1;
    display: flex;
    flex-direction: column;
    .total {
      h3 {
        font-weight: 500;
        font-size: 2rem;
        color: #101828;
      }
    }
  }
  @media screen and (max-width: 400px) {
    flex-direction: column;
    .totals {
      .total {
        display: flex;
        align-items: center;
        border-bottom: 2px solid #eaecf0;
        p {
          padding-right: 5px;
        }
        h3 {
          font-size: 15px;
          font-weight: 300;
        }
      }
    }
  }
}

.PhoneInputInput {
  height: 56px;
  border-radius: 3px;
  padding: 18.5px 14px;
  font-size: 1rem;
  border: 1px solid #c4c4c4 !important;
}
.PhoneInputInput:focus {
  outline: none;
}

.PhoneInputCountry select {
  border: 0.0625rem solid #eaedf2;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}

.sync {
  cursor: pointer;
}

.MuiAutocomplete-inputRoot {
  height: 56px;
}

.reload {
  animation: rotate 1.5s linear 0s infinite running forwards;
}

@keyframes rotate {
  100% {
    transform: rotate(-360deg);
  }
}

.method-card {
  border-radius: 20px;
  border: 1px solid #eaecf0;
  padding: 0.8rem;
  flex-direction: "row";
  align-items: "center";
  width: 100%;
  cursor: pointer;

  .title {
    font-weight: 500;
  }
  .desc {
    line-height: 100%;
  }
}

.coin-card {
  border-radius: 20px;
  border: 1px solid #eaecf0;
  padding: 0.8rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100px;
  cursor: pointer;

  .title {
    font-weight: 500;
    line-height: 1;
    text-align: center;
  }
}

/**** Sign In Page CSS *****/

.signInPage {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 100vh;
  padding-block: 20px;
  padding-inline: 20px;
  > section {
    &:nth-of-type(1) {
      text-align: center;
      img {
        max-width: 135px;
      }
    }
    &:nth-of-type(2) {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      .signInBox {
        background-color: #fff;
        max-width: 480px;
        padding: 16px 24px;
        border-radius: 8px;
        margin: 0 auto;
        .signInBoxHeader {
          text-align: center;
          margin-bottom: 20px;
        }
        .submitBtnContainer {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
      }
    }
    &:nth-of-type(3) {
      display: flex;
      justify-content: space-between;
      a {
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .signInPage {
    > section {
      &:nth-of-type(3) {
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        a {
          text-align: center;
        }
      }
    }
  }
}
