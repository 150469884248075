// FAQ

.faqContent {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  h3 {
    font-size: 20px;
    font-weight: 500;
    margin-block: 30px;
  }
  .faqBlock {
    h4 {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
}
